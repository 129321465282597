define('Usesi_B2bhome/js/b2blogo',['jquery'],
function ($) {
    return function (params) {
        $(document).ready(function () {
            $('#b2bUploadBtn').on('click',function () {
                $('body .logo-upload-form').trigger('submit');
            });
            $('body .logo-upload-form').on('submit', function () {
                var baseurl = params.baseurl + "b2bhome/index/b2blogo";
                var form = $('.logo-upload-form').get(0);
                var formData = new FormData(form);
                var b2bMessages = $('.b2b-image-message');
                b2bMessages.addClass('hide');

                jQuery.ajax({
                    type: "POST",
                    url: baseurl,
                    cache: false,
                    contentType: false,
                    processData: false,
                    data: formData 
                }).done(function (response) {
                        if(response == ''){
                            b2bMessages.removeClass('text-success hide');
                            b2bMessages.addClass('text-danger');
                            b2bMessages.text('Invalid image file format');
                            return true;
                        }
                        response = JSON.parse(response);
                        if(response.status == 'success'){
                            b2bMessages.removeClass('text-danger hide');
                            b2bMessages.addClass('text-success');
                            b2bMessages.text(response.message);
                            setTimeout(function(){
                                var b2bhomeParent = $('.b2bhome-content');
                                b2bhomeParent.find('.c-logo').removeClass('has-logo');
                                b2bhomeParent.find('.c-logo').addClass('has-logo');
                                b2bhomeParent.find('.c-logo').css('background-image', 'url(' + response.image + ')');
                                b2bhomeParent.find('.c-logo').data('img', response.image);
                                $('#b2blogo-upload').btsmodal('hide');
                                $('.b2b-logo-preview').attr('attr',response.image);
                            }, 1000);                            
                        }else{
                            b2bMessages.removeClass('text-success hide');
                            b2bMessages.addClass('text-danger');
                            b2bMessages.text(response.message);
                        }
                    }).fail(function () {
                        b2bMessages.removeClass('text-success hide');
                        b2bMessages.addClass('text-danger');
                        b2bMessages.text('Invalid image file format');
                    })
                });
            });

            $(".logo-upload-form #image").on('change',function () {
                $('.b2b-image-message').addClass('hide');
                readURL(this);
            });

            function readURL(input) {
                if (input.files && input.files[0]) {
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        $('.b2b-logo-preview').attr('src', e.target.result);
                    };
                    reader.readAsDataURL(input.files[0]);
                }
            }

            $("#b2blogo-upload").on('show.bs.modal', function () {
                $('.b2b-image-message').addClass('hide');
                var img = $('.b2bhome-content').find('.c-logo').data('img');
                $('.b2b-logo-preview').attr('src', img);
            });
    };
});
